import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { JobService } from '../../../services/Job.service';
import { ApiService } from '../../../api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HousingImagesDTO } from 'src/app/models/JobModels';
import { ImageModalComponent } from 'src/app/components/image-modal-component/image-modal-component.component';
import { SharedDataService } from 'src/app/shared-data-service.service';



@Component({
  selector: 'app-job-display',
  templateUrl: './job-display.component.html',
  styleUrls: ['./job-display.component.css']
})
export class JobDisplayComponent implements OnInit {
  @ViewChild('housingDetails') housingDetails!: TemplateRef<any>;
  housingDialogRef!: MatDialogRef<any>;
  @ViewChild('transportationDetails') transportationDetails!: TemplateRef<any>;
  transportationDialogRef!: MatDialogRef<any>;


  bannerImages: any[] = [];
  jobPosition: any = null; // Replace with the `JobPosition` model
  employer: any = null; // Replace with the `Employer` model
  errorMessage: string = '';
  portalURL: string;


  googleMapUrl: SafeResourceUrl = '';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private sharedDataService: SharedDataService,
    private jobService: JobService // Replace with your actual service
  ) { }

  ngOnInit(): void {
    this.loadJobData();
  }

  private loadJobData(): void {
    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const jobId = params.get('jobId');
          console.log(jobId);
          if (!jobId) {
            this.errorMessage = 'Job ID is missing.';
            return of(null);
          }
          return this.apiService.getPositionsById(Number.parseInt(jobId)).pipe(
            catchError(() => {
              this.errorMessage = 'Job not found.';
              return of(null);
            })
          );
        })
      )
      .subscribe((job) => {
        if (job) {
          console.log(job);
          this.jobPosition = job;
          this.loadEmployerData(job.employerId);
        }
      });
  }

  openTransportationModal() {
    this.transportationDialogRef = this.dialog.open(this.transportationDetails, {
      width: '400px'
    });
  }


  openHousingModal() {
    this.housingDialogRef = this.dialog.open(this.housingDetails, {
      width: '400px'
    });
  }


  openImageModal(image: HousingImagesDTO): void {
    this.dialog.open(ImageModalComponent, {
      data: image,
      width: '80vw', // Adjust width as needed
      maxHeight: '80vh', // Adjust max height
    });
  }

  openImageInNewTab(image: HousingImagesDTO): void {
    window.open(image.imageUrl, '_blank');
  }


  closeHousingModal() {
    if (this.housingDialogRef) {
      this.housingDialogRef.close();
    }
  }

  closeTransportationModal() {
    if (this.transportationDialogRef) {
      this.transportationDialogRef.close();
    }
  }

  loadEmployerData(employerID: number): void {
    this.apiService.getEmployerForPositionById(employerID).subscribe((employer) => {
      if (employer) {
        console.log(employer);
        this.employer = employer;
        this.bannerImages = employer.banners;

        this.setGoogleMapUrl();
        this.portalURL = this.sharedDataService.getData('portalURL');

      }
    });

  }

  setGoogleMapUrl(): void {
    if (this.employer.address) {
      const addressForGoogle = this.formatAddressForGoogle(this.employer.address);
      const url = `https://www.google.com/maps/embed/v1/place?q=${addressForGoogle},${this.employer.City} ${this.employer.State} ${this.employer.Zip}&key=AIzaSyDiIsnzlSwhauBQf1N-cDGkqb6_6BSh6PI`;
      this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  formatAddressForGoogle(address: string): string {
    if (!address) {
      return "";
    }
    if (address.includes('#')) {
      address = address.split('#')[0];
    }
    return encodeURIComponent(address.trim());
  }
}