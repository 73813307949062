<app-photo-header [slides]="bannerImages" [avatarUrl]="employer?.avatarUrl" [employerName]="employer?.dba"
  [tagline]="employer?.TagLine"></app-photo-header>

<div class="job-display-container" fxLayout="column">
  <!-- Top Header / Logo / Names -->


  <!-- Sub-navigation if needed -->
  <nav class="sub-nav" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="portalURL && jobPosition">
    <a mat-button target="_blank" [href]="portalURL + '/Employers/EmployerProfile?viewpro=' + jobPosition?.employerId">About</a>
    <a mat-button target="_blank" [href]="portalURL + '/Employers/EmployerProfile?viewpro=' + jobPosition?.employerId + '#jobs'">Open Positions</a>
  </nav>

  <!-- Main Content (Two Column Layout) -->
  <div class="main-content" fxLayout="row" fxLayoutGap="24px">
    <!-- LEFT COLUMN -->
    <div class="left-column" fxFlex="70" fxLayout="column" fxLayoutGap="16px">

      <!-- Job Title, Description -->
      <mat-card class="job-details">
        <h2>{{ jobPosition?.jobTitle }}</h2>
        <quill-view [content]="jobPosition?.jobDescription" format="html" theme="snow"></quill-view>

        <p></p>
      </mat-card>

      <!-- Dates and Skills & Competencies -->
      <mat-card class="job-info" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50">
          <h4>Dates</h4>
          <p>{{ jobPosition?.postDate | date }} - {{ jobPosition?.removalDate | date }}</p>
        </div>
        <div fxFlex="50" *ngIf="jobPosition?.skills">
          <h4>Skills & Competencies</h4>
          <p>{{ jobPosition?.skills }}</p>
        </div>
      </mat-card>

      <!-- Hours and Openings -->
      <mat-card class="job-info" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50" *ngIf="jobPosition?.hoursPerWeek">
          <h4>Hours per Week</h4>
          <p>{{ jobPosition?.hoursPerWeek }}</p>
        </div>
        <div fxFlex="50" *ngIf="jobPosition?.openings">
          <h4># of Openings</h4>
          <p>{{ jobPosition?.openings }}</p>
        </div>
      </mat-card>

      <!-- Wages & Compensation -->
      <mat-card class="wages-compensation" fxLayout="column" fxLayoutGap="10px">
        <h3>Wages & Compensation</h3>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">
            <h4>Hourly Wage</h4>
            <p>${{ jobPosition?.wageMin }} - ${{ jobPosition?.wageMax }} per hour</p>
          </div>
          <div fxFlex="50">
            <h4>Additional Compensation</h4>
            <p>
              {{ jobPosition?.overtimePay ? 'Overtime Pay' : '' }}
              {{ jobPosition?.pieceWorkPay ? ', Piece-work Pay' : '' }}
              {{ jobPosition?.gratuities ? ', Gratuities' : '' }}
              {{ jobPosition?.bonus ? ', Bonus' : '' }}
            </p>
          </div>
        </div>
        <h4>Other Compensation</h4>
        <p>{{ jobPosition?.otherCompensation }}</p>
        <h4>Benefits</h4>
        <p>
          {{ jobPosition?.healthBenefits ? 'Health Insurance' : '' }}
          {{ jobPosition?.dentalBenefits ? ', Dental Insurance' : '' }}
          {{ jobPosition?.retentionBonus ? ', Retention Bonus' : '' }}
          {{ jobPosition?.referralBonus ? ', Referral Bonus' : '' }}
          {{ jobPosition?.offer401k ? ', 401(k)' : '' }}
          {{ jobPosition?.paidTimeOff ? ', Paid Time Off' : '' }}
        </p>

        <div
        class="icons-section"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="32px"
      >
        <!-- Housing Icon and Text -->
        <div *ngIf="jobPosition?.employerProvidesHousing" fxLayout="column" fxLayoutAlign="center center">
          <button mat-icon-button class="large-button" (click)="openHousingModal()">
            <mat-icon class="icons-job-display large-icon">home</mat-icon>
          </button>
          <p>Offers Housing</p>
        </div>
      
        <!-- Transportation Icon and Text -->
        <div *ngIf="jobPosition?.employerProvidesTransportation" fxLayout="column" fxLayoutAlign="center center">
          <button mat-icon-button class="large-button" (click)="openTransportationModal()">
            <mat-icon class="icons-job-display large-icon">directions_bus</mat-icon>
          </button>
          <p>Offers Transportation</p>
        </div>
      </div>
      

      </mat-card>

      <!-- Qualifications, Requirements, Other Info, etc. -->
      <mat-card class="qualifications-info">
        <h4>Qualifications</h4>
        <p *ngIf="jobPosition?.visaTypes">Visa Types Accepted: {{ jobPosition?.visaTypes }}</p>
        <p *ngIf="jobPosition?.education">Minimum Education: {{ jobPosition?.education }}</p>
        <p *ngIf="jobPosition?.minimumExperience">Minimum Experience: {{ jobPosition?.minimumExperience }}</p>
        <p *ngIf="jobPosition?.certifications">Certifications: {{ jobPosition?.certifications }}</p>
        <p *ngIf="jobPosition?.requirements">Requirements: {{ jobPosition?.requirements }}</p>

        <h4 *ngIf="jobPosition?.otherInformation">Other Information</h4>
        <p>{{ jobPosition?.otherInformation }}</p>
      </mat-card>
    </div>
    <!-- END LEFT COLUMN -->

    <!-- RIGHT COLUMN -->
    <div class="right-column" fxFlex="30" fxLayout="column" fxLayoutGap="16px">

      <!-- Action Buttons at top if desired -->
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <a mat-raised-button color="primary" class="apply-button" target="_blank" [href]="portalURL + '/Employers/EmployerPositionApplication?viewpro=' + jobPosition?.employerPositionId">Apply</a>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

        <button mat-raised-button>Bookmark</button>
        <button mat-icon-button aria-label="Hide this job">
          <mat-icon>visibility_off</mat-icon>
        </button>
      </div>

      <!-- MAP or Employer Info or both -->
      <mat-card class="employer-map">
        <h3>Location</h3>
        <!-- Insert your map component or iframe here -->
        <div class="map-placeholder">
          <iframe *ngIf="googleMapUrl" [src]="googleMapUrl" width="300" height="200" frameborder="0" style="border:0"
            allowfullscreen></iframe>
        </div>
      </mat-card>

      <!-- Employer Details -->
      <mat-card *ngIf="employer" class="employer-details" fxLayout="column" fxLayoutGap="6px">
        <h3>{{ employer?.dba }}</h3>
        <p>{{ employer?.address }} {{ employer?.city }} {{ employer?.state }} {{ employer?.zip }}</p>
        <p *ngIf="employer?.Telephone">{{ employer?.telephone }}</p>
        <p *ngIf="employer?.WebSite"><a href="{{ employer?.webSite }}" target="_blank">Website</a></p>
        <p *ngIf="employer?.NumPermStaff">{{ employer?.numPermStaff }} Permanent Staff</p>
        <p *ngIf="employer?.NumSeasonalStaff">{{ employer?.numSeasonalStaff }} Seasonal Staff</p>


      </mat-card>

    </div>
    <!-- END RIGHT COLUMN -->
  </div>
  <!-- END Main Content -->
</div>

<!-- Housing Details Dialog -->
<ng-template #housingDetails>
  <h1 mat-dialog-title>Housing Details</h1>
  <mat-dialog-content>
    <p>Location:
      <span *ngIf="jobPosition?.onProperty">On Property</span>
      <span *ngIf="jobPosition?.offProperty">Off Property</span>
    </p>
    <p>Shared: {{ jobPosition?.shared ? 'Yes' : 'No' }}</p>
    <p>Individual: {{ jobPosition?.individual ? 'Yes' : 'No' }}</p>
    <p>Housing Type:
      <span *ngIf="jobPosition?.house">House</span>
      <span *ngIf="jobPosition?.apartment">Apartment</span>
      <span *ngIf="jobPosition?.dormitory">Dormitory</span>
      <span *ngIf="jobPosition?.otherHousing">Other: {{ jobPosition?.otherHousing }}</span>
    </p>
    <p>Employer Provides Housing: {{ jobPosition?.employerProvidesHousing ? 'Yes' : 'No' }}</p>
    <p>Employer Owned: {{ jobPosition?.employerOwned ? 'Yes' : 'No' }}</p>
    <p>Employer Paid: {{ jobPosition?.employerHousingAmount ? ('$' + jobPosition?.employerHousingAmount + ' / week') :
      'Not Provided' }}</p>
    <p>Employee Paid: {{ jobPosition?.workerHousingAmount ? ('$' + jobPosition?.workerHousingAmount + ' / week') : 'Not
      Provided' }}</p>
    <p>Deposit: {{ jobPosition?.deposit ? ('$' + jobPosition?.deposit) : 'No Deposit Required' }}</p>
    <p>Utilities Included: {{ jobPosition?.utilitiesIncluded ? 'Yes' : 'No' }}</p>

    <h3 *ngIf="jobPosition?.housingImages && jobPosition?.housingImages.length"> Housing Images</h3>
    <div class="housing-image-grid">
      <div *ngFor="let image of jobPosition?.housingImages" class="housing-image-item" (click)="openImageModal(image)">
        <img [src]="image.imageUrl" [alt]="image.photoCaption || 'Housing Image'" />
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="closeHousingModal()" color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #transportationDetails>
  <h1 mat-dialog-title>Transportation Details</h1>
  <mat-dialog-content>
    <p>Employer Provides Transportation: {{ jobPosition?.employerProvidesTransportation ? 'Yes' : 'No' }}</p>
    <p>Available Transportation Options:</p>
    <ul>
      <li *ngIf="jobPosition?.publicTransportation">Public Transportation</li>
      <li *ngIf="jobPosition?.shuttle">Shuttle</li>
      <li *ngIf="jobPosition?.car">Car</li>
      <li *ngIf="jobPosition?.bicycle">Bicycle</li>
      <li *ngIf="jobPosition?.carpool">Carpool</li>
      <li *ngIf="jobPosition?.otherTransport">Other: {{ jobPosition?.otherTransport }}</li>
    </ul>
    <p>Transportation Cost:
      {{ jobPosition?.transportCost ? ('$' + jobPosition?.transportCost + ' / week') : 'No Cost Provided' }}
    </p>


  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="closeTransportationModal()" color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>